.draggable-item {
    cursor: move;
}

.cursor-pointer {
    cursor: pointer;
}

#map-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 70.4%;
}

#map {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}