.mapboxgl-ctrl-top-right,
.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right {
    display: none;
}

.scrollable-list {
    max-height: 500px;
    overflow-y: auto;
}

.list-group::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari 和 Opera */
}

.list-group {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
}

.mapboxgl-popup-content {
    color: black;
}

.legend {
    color: black;
    box-shadow: 5px 5px 8px 0px rgb(0 0 0 / 67%) !important;
    border-radius: 5px;
    border: 2px solid rgba(0, 0, 0, 0.517);
}